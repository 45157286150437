<template>
  <div :class="containerClass" @click="onClick($event)">
    <div class="p-hidden-accessible">
      <input
        ref="input"
        :id="inputId"
        type="checkbox"
        :checked="value === 1"
        :tabindex="tabindex"
        :disabled="disabled"
        :aria-labelledby="ariaLabelledby"
        :aria-label="ariaLabel"
        @keydown="onKeyDown($event)"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        v-bind="inputProps"
      />
    </div>
    <span span class="p-sr-only" aria-live="polite">{{ ariaValueLabel }}</span>
    <div ref="box" :class="['p-checkbox-box', { 'p-highlight': value != 0, 'p-disabled': disabled, 'p-focus': focused }]">
      <template v-if="value === 1">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon p-checkbox-icon" aria-hidden="true"><path d="M4.86199 11.5948C4.78717 11.5923 4.71366 11.5745 4.64596 11.5426C4.57826 11.5107 4.51779 11.4652 4.46827 11.4091L0.753985 7.69483C0.683167 7.64891 0.623706 7.58751 0.580092 7.51525C0.536478 7.44299 0.509851 7.36177 0.502221 7.27771C0.49459 7.19366 0.506156 7.10897 0.536046 7.03004C0.565935 6.95111 0.613367 6.88 0.674759 6.82208C0.736151 6.76416 0.8099 6.72095 0.890436 6.69571C0.970973 6.67046 1.05619 6.66385 1.13966 6.67635C1.22313 6.68886 1.30266 6.72017 1.37226 6.76792C1.44186 6.81567 1.4997 6.8786 1.54141 6.95197L4.86199 10.2503L12.6397 2.49483C12.7444 2.42694 12.8689 2.39617 12.9932 2.40745C13.1174 2.41873 13.2343 2.47141 13.3251 2.55705C13.4159 2.64268 13.4753 2.75632 13.4938 2.87973C13.5123 3.00315 13.4888 3.1292 13.4271 3.23768L5.2557 11.4091C5.20618 11.4652 5.14571 11.5107 5.07801 11.5426C5.01031 11.5745 4.9368 11.5923 4.86199 11.5948Z" fill="currentColor"></path></svg>
      </template>
      <template v-else-if="value === 2">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon p-checkbox-icon" aria-hidden="true"><path d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z" fill="currentColor"></path></svg>
      </template>
      <span v-else class="p-checkbox-icon"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TriStateCheckbox',
  emits: ['click', 'input', 'change', 'keydown', 'focus', 'blur'],
  props: {
    value: null,
    inputId: {
      type: String,
      default: null
    },
    inputProps: {
      type: null,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: 0
    },
    'aria-labelledby': {
      type: String,
      default: null
    },
    'aria-label': {
      type: String,
      default: null
    }
  },
  data() {
    return {
      focused: false
    };
  },
  methods: {
    updateModel() {
      if (!this.disabled) {
          let newValue;
          switch (this.value) {
              case 1:
                  newValue = 2;
                  break;
              case 2:
                  newValue = 0;
                  break;
              default:
                  newValue = 1;
                  break;
          }
          this.$emit('input', newValue);
      }
    },
    onClick() {
        this.updateModel();
        // this.$emit('click', event);
        // this.$emit('change', event);
        this.$refs.input.focus();
    },
    onKeyDown(event) {
        if (event.code === 'Enter') {
            this.updateModel();
            this.$emit('keydown', event);
            event.preventDefault();
        }
    },
    onFocus() {
        this.focused = true;
        // this.$emit('focus', event);
    },
    onBlur() {
        this.focused = false;
        // this.$emit('blur', event);
    }
  },
  computed: {
    containerClass() {
        return [
            'p-checkbox p-component',
            {
                'p-checkbox-checked': this.value === 1,
                'p-checkbox-disabled': this.disabled,
                'p-checkbox-focused': this.focused
            }
        ];
    },
    ariaValueLabel() {
      return this.value;
    }
  },
}
</script>

<style scoped>
.p-component, .p-component * {
  box-sizing: border-box;
}
.p-checkbox {
  width: 22px;
  height: 22px;
}
.p-checkbox {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: bottom
}

.p-checkbox.p-checkbox-disabled {
  cursor: default
}

.p-checkbox-box {
  align-items: center;
  display: flex;
  justify-content: center
}

.p-hidden-accessible {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.p-hidden-accessible input, .p-hidden-accessible select {
  transform: scale(0);
}
.p-sr-only {
  clip: rect(1px,1px,1px,1px);
  word-wrap: normal!important;
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #495057;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #00711e;
  background: #00711e;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 14px;
  height: 14px;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
</style>